export default class Config {
    static SIO_MAKER_CLOUD = "https://sio.sit.makercloud.io";
    static API_MAKER_CLOUD = "https://api.sit.makercloud.io";
    static SSO_MAKER_CLOUD = "https://sso.sit.makercloud.io";
    static ET_API_MAKER_CLOUD = "https://et-api.sit.makercloud.io";
    static LICENSE_API_MAKER_CLOUD = "https://license-api.sit.makercloud.io";

    static OPEN_API_ENDPOINT = "https://open-api.sit.makercloud.io/{basePath}/{openApiToken}";

    static IMAGE_MAKER_CLOUD = "https://img1.sit.makercloud.io";
    static GOOGLE_CLIENT_ID = "1046207926868-dhc5jad42kv28a4mhll0lii84dpr5110.apps.googleusercontent.com";
    static GOOGLE_SERVICE_ACCOUNT_CLIENT_ID = "1046207926868-dhc5jad42kv28a4mhll0lii84dpr5110.apps.googleusercontent.com";

    static GOOGLE_API_KEY = "AIzaSyCNMUFbIwIO14hCIRgThWOaYP_aeEGbGTI";

}
